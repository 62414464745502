import z from "zod";

const SiteSchema = z.object({
  id: z.number(),
  name: z.string(),
  full_name: z.string(),
  region: z.string().nullish(),
});

const SitePartialSchema = SiteSchema.pick({ id: true, full_name: true });

const SitePartialOptionalSchema = z.object({
  id: z.number().nullable(),
  full_name: z.string().nullable(),
});

const SitesSchema = z.array(SiteSchema);

export { SitePartialOptionalSchema, SitePartialSchema, SiteSchema, SitesSchema };
