import type { RouteLocationNormalized } from "vue-router";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";

/**
 * Refreshes authentication status guard
 *
 * If we haven't attempted to check the authentication of the user
 * Check the authentication status via api call before continuing
 * We don't try to authenticate on a guest route to prevent an endless loop
 * As the global axios interceptor will redirect to the login on a 401 status
 * @param to route that we are navigating to
 * @param isGuest Meta guest check override the
 * @returns `true` to continue
 */
export default async function refreshAuthenticationGuard(
  to: RouteLocationNormalized,
  isGuest: boolean | undefined = undefined
): Promise<boolean> {
  const authenticationStore = useAuthenticationStore();
  const isToNonGuestRoute = [false, undefined].includes(isGuest ?? to.meta.isGuest);

  if (!authenticationStore.hasAttemptedToAuthenticate && isToNonGuestRoute) {
    await authenticationStore.setAuthenticatedUser();
  }

  return true;
}
