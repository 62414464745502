enum TaskPublishedStatusEnum {
  /** Draft published status. Indicates that the task is a draft and has never been published */
  draft = "draft",
  /**
   * Pending changes published status.
   * - Indicates that the task is NOT a draft, is live and has a unpublished draft task version
   */
  pendingChanges = "pending_changes",
  /** Published published status. Indicates that the task is published and is live to customers */
  published = "published",
  /** Archived published status. Indicates that the task is archived */
  archived = "archived",
}

export default TaskPublishedStatusEnum;
