import type { RouteLocationNormalized } from "vue-router";
import AnalyticEventsEnum from "../enums/AnalyticEventsEnum.ts";
import analyticTrack from "../functions/AnalyticEvent.ts";

/**
 * Log out to amplitude to track page views.
 * Useful for tracking page views in single page applications.
 * Also used to track application usage.
 * @param to The route that we are navigating to.
 * @returns `true` to continue
 */
export default function analyticGuard(to: RouteLocationNormalized): boolean {
  analyticTrack(AnalyticEventsEnum.pageView, {
    routeName: to.name,
    routePath: to.path,
  });

  return true;
}
