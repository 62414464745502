/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import globalAxiosCatchInterceptor from "@/app/http/GlobalAxiosCatchInterceptor.ts";
import versionMismatchInterceptor from "@/app/http/VersionMismatchInterceptor.ts";
import axios from "axios";

const HttpClient = axios;

/**
 * Recommended by laravel sanctum
 */
HttpClient.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
HttpClient.defaults.withCredentials = true;
HttpClient.defaults.headers.common["Content-Type"] = "application/json";

/**
 * Global interceptor to handle all errors
 */
HttpClient.interceptors.response.use(
  async (response) => versionMismatchInterceptor(response),
  globalAxiosCatchInterceptor
);

export default HttpClient;
