import conditionOrSuperAdmin from "@/auth/functions/ConditionOrSuperAdmin.ts";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import canPerformPermission from "./CanPerformPermission.ts";

/**
 * Check if the authenticated user can perform a company permission.
 * @param permission Permission to check
 * @returns boolean Can the user perform the permission
 */
function canPerformCompanyPermission(permission: string): boolean {
  const authenticationStore = useAuthenticationStore();
  let permissions = authenticationStore.currentCompanyPermissions;

  if (!Array.isArray(permissions)) {
    permissions = [];
  }

  return conditionOrSuperAdmin(canPerformPermission(permissions, permission));
}

export default canPerformCompanyPermission;
