import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import type { RouteLocationNormalized } from "vue-router";

/**
 * Makes sure the authenticated user is a super admin
 * @param to the to route location
 * @returns returns boolean to continue or to not continue
 */
export default function versionMismatchGuard(to: RouteLocationNormalized): boolean {
  const shouldRefresh = useAuthenticationStore().shouldPerformVersionMismatchRefresh();

  if (shouldRefresh) {
    window.location.href = to.fullPath;

    return false;
  }

  return true;
}
