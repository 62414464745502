import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";

/**
 * Returns the user to the login screen if they are unauthenticated
 *
 * If the user is authenticated then continue
 * Otherwise we take them to the login route
 * @param to the target route location
 * @returns returns boolean to continue or the login route
 */
export default function authenticateGuard(to: RouteLocationNormalized): RouteLocationRaw | boolean {
  const authenticationStore = useAuthenticationStore();

  if ((to.meta.isGuest ?? false) || authenticationStore.isAuthenticated) {
    return true;
  }

  return { name: "login" };
}
