/* istanbul ignore file -- @preserve */
/* eslint-disable @typescript-eslint/promise-function-async */
import taskHasDraftVersionRedirectGuard from "@/task/guards/TaskHasDraftVersionRedirectGuard.ts";
import type { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

declare module "vue-router" {
  interface RouteMeta {
    isSuperAdmin?: boolean;
  }
}

/** @todo rename file to `SuperAdminRoutes.ts` */

const superAdminMeta = {
  isSuperAdmin: true,
};

const getCompanyIdProp = (route: RouteLocationNormalized): { companyId: number | undefined } => ({
  companyId: route.params["companyId"] !== undefined ? Number(route.params["companyId"]) : undefined,
});

const getSiteIdProp = (route: RouteLocationNormalized): { siteId: number | undefined } => ({
  siteId: route.params["siteId"] !== undefined ? Number(route.params["siteId"]) : undefined,
});

const getUserIdProp = (route: RouteLocationNormalized): { userId: number | undefined } => ({
  userId: route.params["userId"] !== undefined ? Number(route.params["userId"]) : undefined,
});

const routes: RouteRecordRaw[] = [
  {
    path: "/super-admin",
    component: () => import("@/superAdmin/pageLayouts/adminPageLayout/TheAdminPageLayout.vue"),
    meta: {
      ...superAdminMeta,
    },
    redirect: () => {
      return { name: "superAdmin" };
    },
    children: [
      {
        path: "dashboard",
        name: "superAdmin",
        component: {
          template: "<div>Super Admin Dashboard</div>",
        },
        meta: {
          ...superAdminMeta,
        },
      },
      {
        path: "users",
        children: [
          {
            path: "",
            name: "superAdminUsersIndex",
            component: () => import("@/user/pages/superAdminUsersIndex/TheSuperAdminUsersIndexPage.vue"),
            meta: {
              ...superAdminMeta,
            },
          },
          {
            path: ":userId",
            name: "superAdminUserDetail",
            component: () => import("@/user/pages/superAdminUserDetail/TheSuperAdminUserDetailPage.vue"),
            props: (route) => ({
              ...getUserIdProp(route),
            }),
            meta: {
              ...superAdminMeta,
            },
            redirect: () => {
              return { name: "superAdminUserOverview" };
            },
            children: [
              {
                path: "overview",
                name: "superAdminUserOverview",
                component: () => import("@/user/views/superAdminUserOverview/TheSuperAdminUserOverviewView.vue"),
                meta: {
                  ...superAdminMeta,
                },
              },
            ],
          },
        ],
      },
      {
        path: "scheduler",
        name: "superAdminScheduler",
        component: () =>
          import("@/scheduler/pages/superAdminScheduledItemsIndex/TheSuperAdminScheduledItemsIndexPage.vue"),
        meta: {
          ...superAdminMeta,
        },
      },
      {
        path: "manage",
        children: [
          {
            path: "",
            name: "superAdminManage",
            component: () => import("@/task/pages/superAdminManageIndex/TheSuperAdminManageIndexPage.vue"),
            meta: {
              ...superAdminMeta,
            },
          },
          {
            path: ":slug",
            children: [
              {
                path: "",
                name: "superAdminManageTasksIndex",
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/pages/superAdminManageTasksIndex/TheSuperAdminManageTasksIndexPage.vue"),
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  slug: route.params["slug"],
                }),
                meta: {
                  ...superAdminMeta,
                },
              },
              {
                path: ":taskId",
                name: "superAdminManageTaskDetail",
                component: /* istanbul ignore next */ () =>
                  import("@/task/pages/superAdminTaskManageDetail/TheSuperAdminTaskManageDetailPage.vue"),
                props: /* istanbul ignore next */ (route) => ({
                  taskId: Number(route.params["taskId"]),
                  slug: route.params["slug"],
                }),
                /* istanbul ignore next -- @preserve */
                beforeEnter: (to) =>
                  taskHasDraftVersionRedirectGuard(to, (slug, draftTaskVersionId) => ({
                    name: "superAdminTaskVersionUpdate",
                    params: { slug, taskVersionId: draftTaskVersionId },
                  })),
                meta: {
                  ...superAdminMeta,
                },
              },
              {
                path: "task-versions/:taskVersionId/update",
                name: "superAdminTaskVersionUpdate",
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  taskVersionId: Number(route.params["taskVersionId"]),
                  slug: route.params["slug"],
                }),
                component: /* istanbul ignore next -- @preserve */ () =>
                  import("@/task/pages/superAdminTaskVersionUpdate/TheSuperAdminTaskVersionUpdatePage.vue"),
                meta: {
                  ...superAdminMeta,
                },
              },
            ],
          },
        ],
      },
      {
        path: "companies",
        component: () => import("@/company/pages/superAdminCompanies/TheSuperAdminCompaniesPage.vue"),
        meta: {
          ...superAdminMeta,
        },
        children: [
          {
            path: "",
            name: "superAdminCompaniesIndex",
            component: () => import("@/company/pages/superAdminCompaniesIndex/TheSuperAdminCompaniesIndexPage.vue"),
            meta: {
              ...superAdminMeta,
            },
          },
          {
            path: "/super-admin/companies-create",
            name: "superAdminCompanyCreate",
            component: () => import("@/company/pages/superAdminCompanyCreate/TheSuperAdminCompanyCreatePage.vue"),
            meta: {
              ...superAdminMeta,
            },
          },
          {
            path: ":companyId/staff/:userId",
            name: "superAdminCompanyStaffDetail",
            component: () =>
              import("@/company/pages/superAdminCompanyStaffDetail/SuperAdminCompanyStaffDetailPage.vue"),
            props: (route) => ({
              ...getCompanyIdProp(route),
              ...getUserIdProp(route),
            }),
            redirect: /* istanbul ignore next -- @preserve */ () => {
              return { name: "superAdminCompanyStaffDetailSettings" };
            },
            meta: {
              ...superAdminMeta,
            },
            children: [
              {
                name: "superAdminCompanyStaffDetailSettings",
                path: "settings",
                component: /* istanbul ignore next -- @preserve  */ () =>
                  import(
                    "@/company/pages/superAdminCompanyStaffDetailSettings/SuperAdminCompanyStaffDetailSettingsPage.vue"
                  ),
                props: /* istanbul ignore next -- @preserve */ (route) => ({
                  ...getCompanyIdProp(route),
                  ...getUserIdProp(route),
                }),
                meta: {
                  ...superAdminMeta,
                },
              },
            ],
          },
          {
            path: ":companyId",
            component: () => import("@/company/pages/superAdminCompanyDetail/TheSuperAdminCompanyDetailPage.vue"),
            props: (route) => ({
              ...getCompanyIdProp(route),
            }),
            meta: {
              ...superAdminMeta,
            },
            children: [
              {
                path: "dashboard",
                name: "superAdminCompanyOverview",
                component: () =>
                  import("@/company/views/superAdminCompanyOverview/TheSuperAdminCompanyOverviewView.vue"),
                props: (route) => ({
                  ...getCompanyIdProp(route),
                }),
                meta: {
                  ...superAdminMeta,
                },
              },
              {
                path: "sites",
                meta: {
                  ...superAdminMeta,
                },
                children: [
                  {
                    path: "",
                    name: "superAdminCompanySitesIndex",
                    props: (route) => ({
                      ...getCompanyIdProp(route),
                    }),
                    component: () =>
                      import("@/site/views/superAdminCompanySitesIndex/TheSuperAdminCompanySitesIndexView.vue"),
                    meta: {
                      ...superAdminMeta,
                    },
                  },
                  {
                    path: "/super-admin/companies/:companyId/sites-create",
                    name: "superAdminCompanySiteCreate",
                    component: () =>
                      import("@/site/views/superAdminCompanySiteCreate/TheSuperAdminCompanySiteCreateView.vue"),
                    props: (route) => ({
                      ...getCompanyIdProp(route),
                    }),
                    meta: {
                      ...superAdminMeta,
                    },
                  },
                ],
              },
              {
                path: "staff",
                component: () => import("@/company/views/superAdminCompanyStaff/TheSuperAdminCompanyStaffView.vue"),
                meta: {
                  ...superAdminMeta,
                },
                children: [
                  {
                    path: "",
                    name: "superAdminCompanyStaffIndex",
                    component: () =>
                      import("@/company/views/superAdminCompanyStaffIndex/TheSuperAdminCompanyStaffIndexView.vue"),
                    props: (route) => ({
                      ...getCompanyIdProp(route),
                    }),
                    meta: {
                      ...superAdminMeta,
                    },
                  },
                  {
                    path: "/super-admin/companies/:companyId/staff-create",
                    name: "superAdminCompanyStaffCreate",
                    component: () =>
                      import("@/company/views/superAdminCompanyStaffCreate/TheSuperAdminCompanyStaffCreateView.vue"),
                    props: (route) => ({
                      ...getCompanyIdProp(route),
                    }),
                    meta: {
                      ...superAdminMeta,
                    },
                  },
                ],
              },
              {
                path: "triggers",
                name: "superAdminCompanyTriggersIndex",
                component: () => import("@/workflows/views/triggerIndexView/TriggerIndexContainer.vue"),
                props: (route) => ({
                  ...getCompanyIdProp(route),
                }),
                meta: {
                  ...superAdminMeta,
                },
              },
              {
                path: "workflows",
                name: "superAdminCompanyWorkflowsIndex",
                component: () => import("@/workflows/views/workflowIndex/WorkflowIndexContainer.vue"),
                props: (route) => ({
                  ...getCompanyIdProp(route),
                }),
                meta: {
                  ...superAdminMeta,
                },
              },
            ],
          },
          {
            path: "/super-admin/companies/:companyId/sites/:siteId",
            name: "superAdminCompanySiteDetail",
            props: (route) => ({
              ...getCompanyIdProp(route),
              ...getSiteIdProp(route),
            }),
            component: () => import("@/site/pages/superAdminCompanySiteDetail/TheSuperAdminCompanySiteDetailPage.vue"),
            meta: {
              ...superAdminMeta,
            },
          },
        ],
      },
      {
        path: "workflows",
        name: "superAdminWorkflows",
        component: () => import("@/workflows/views/workflowDefinitionIndex/WorkflowDefinitionIndexContainer.vue"),
        meta: {
          ...superAdminMeta,
        },
      },
    ],
  },
];

export default routes;
