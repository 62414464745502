import * as Sentry from "@sentry/vue";
import errorHandler from "./ErrorHandler.ts";

/**
 * Boot the global error handler.
 */
function bootGlobalErrorHandler(): void {
  window.addEventListener("error", (event) => {
    errorHandler(event.error);
  });

  window.addEventListener("unhandledrejection", (error) => {
    errorHandler(new Error(error.reason));
  });

  window.addEventListener("messageerror", (event) => {
    Sentry.captureException(event);
  });
}

export default bootGlobalErrorHandler;
