enum PermissionEnum {
  siteTaskViewAll = "site-task:view-all",
  siteTaskView = "site-task:view",

  manageView = "manage:view",
  manageShow = "manage:show",
  manageCreate = "manage:create",
  managePublish = "manage:publish",
  manageUpdate = "manage:update",
  manageDelete = "manage:delete",

  companyStaffView = "company-staff:view",
  companyStaffShow = "company-staff:show",
  companyStaffInvite = "company-staff:invite",
  companyStaffRemove = "company-staff:remove",
  companyStaffUpdate = "company-staff:update",

  companySitesView = "company-sites:view",
  companySitesShow = "company-sites:show",
  companySitesUpdate = "company-sites:update",
  companySitesCreate = "company-sites:create",
  companySitesRemove = "company-sites:remove",

  companySettingsView = "company-settings:view",
  companySettingsUpdate = "company-settings:update",

  companyDocumentViewNotInAudience = "company-document:view-not-in-audience",
  companyDocumentViewAll = "company-document:view-all",
  companyDocumentUpdate = "company-document:update",

  siteStaffView = "site-staff:view",

  siteSettingsView = "site-settings:view",
  siteAssetsView = "site-assets:view",

  siteDocumentCreate = "site-document:create",
  siteDocumentUpdate = "site-document:update",
}

export default PermissionEnum;
