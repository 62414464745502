import analyticGuard from "@/analytics/guards/analyticGuard.ts";
import authenticateGuard from "@/auth/guards/AuthenticateGuard.ts";
import refreshAuthenticationGuard from "@/auth/guards/RefreshAuthenticationStatusGuard.ts";
import versionMismatchGuard from "@/auth/guards/VersionMismatchGuard.ts";
import parseRouteParamNumberGuard from "@/routing/guards/ParseRouteParamGuard.ts";
import superAdminGuard from "@/superAdmin/guards/SuperAdminGuard.ts";
import type { Router } from "vue-router";
import { createMemoryHistory, createRouter, createWebHistory } from "vue-router";
import Routes from "./Routes.ts";

/**
 * Create a new vue router instance
 * @param history the history type to use
 * @returns an instance of vue router
 */
function newRouter(history: "memory" | "web" = "web"): Router {
  const router = createRouter({
    history: history === "web" ? createWebHistory() : createMemoryHistory(),
    routes: [...Routes],
  });

  router.beforeEach(async (to) => refreshAuthenticationGuard(to));
  router.beforeEach(authenticateGuard);
  router.beforeEach(superAdminGuard);
  router.beforeEach(parseRouteParamNumberGuard);
  router.beforeEach(versionMismatchGuard);
  router.beforeEach(analyticGuard);
  return router;
}

export default newRouter;
