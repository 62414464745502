import z from "zod";

const PermissionsSchema = z.array(z.string());

const UserCurrentCompanySitesPermissionsSchema = z.array(
  z.object({
    site_id: z.number(),
    permissions: PermissionsSchema,
  })
);

export { PermissionsSchema, UserCurrentCompanySitesPermissionsSchema };
