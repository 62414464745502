import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { createRouteLocation } from "../functions/NavigateToErrorPage.ts";

/**
 * Ensures all route param ids are numbers
 *
 * - Navigate to the error page with a 404 message if the route param is not a number
 * - If you have an `id` that does not need to be numeric call it `slug`, `hash` or `name` instead of `id`
 * @param to route that we are navigating to
 * @returns the parsed number
 */
export default function parseRouteParamNumberGuard(to: RouteLocationNormalized): RouteLocationRaw | boolean {
  const { params } = to;

  // filter out all keys that don't end with Id, id, or ID
  const idParams = Object.keys(params).filter((key) => {
    return (
      (key.endsWith("Id") || key.endsWith("id") || key.endsWith("ID")) && !key.endsWith("uuid") && !key.endsWith("UUID")
    );
  });

  // filter out all keys that have non-numeric values
  const hasNonNumericIdParams = idParams.filter((key) => {
    const parsed = Number(params[key]);

    return Number.isNaN(parsed);
  });

  // if any non numeric keys were found, return a 404 error location route to navigate there
  if (hasNonNumericIdParams.length > 0) {
    return createRouteLocation(404, `${to.path} contains non numeric ids`);
  }

  // otherwise, continue to the next step
  return true;
}
