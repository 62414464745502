import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";

/**
 * Returns either the condition or if the user is a super admin.
 *
 * Allow super admins to bypass the condition.
 * @param condition  The condition to check.
 * @returns boolean The result of the condition or if the user is a super admin.
 */
function conditionOrSuperAdmin(condition: boolean): boolean {
  const authenticationStore = useAuthenticationStore();

  if (authenticationStore.isSuperAdmin) {
    return true;
  }

  return condition;
}

export default conditionOrSuperAdmin;
