<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseApp",

  expose: [],
});
</script>

<style>
@import "floating-vue/dist/style.css";
</style>
