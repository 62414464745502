import { z } from "zod";

const taskSettingsSchema = z.object({
  can_be_scheduled: z.boolean(),
  uses_targeting: z.boolean(),
  uses_review_process: z.boolean(),
  should_use_audience: z.boolean(),
});

export default taskSettingsSchema;
