import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import type { RouteLocationRaw } from "vue-router";

/**
 * Logout Guard
 *
 * - Logout the authenticated user
 * @returns redirect to login route
 */
export default async function beforeRouteEnter(): Promise<RouteLocationRaw | false> {
  await useAuthenticationStore().logoutAuthenticatedUser();

  window.location.href = "/";

  return false;
}
