import type { RouteLocationRaw } from "vue-router";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";

/**
 * Guard to redirect the user to /home if they are already authenticated
 * @param options Options for this guard
 * @param options.refreshAuthentication Whether the guard should attempt to set the authenticated user
 * @returns home route or true to continue
 */
export default async function redirectIfAuthenticatedGuard(
  options: {
    refreshAuthentication?: boolean;
  } = {}
): Promise<RouteLocationRaw | boolean> {
  const authenticationStore = useAuthenticationStore();

  if (options.refreshAuthentication ?? false) {
    await authenticationStore.setAuthenticatedUser();
  }

  if (authenticationStore.isAuthenticated) {
    return { name: "home" };
  }

  return true;
}
