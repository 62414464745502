import type { RouteLocationRaw } from "vue-router";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";

/**
 * Redirects the user to the super admin route if super admin
 * @returns returns super admin route or bool if not super admin
 */
export default function redirectToSuperAdminHomeGuard(): RouteLocationRaw | boolean {
  const authenticationStore = useAuthenticationStore();

  if (authenticationStore.isAuthenticated && authenticationStore.isSuperAdmin) {
    return { name: "superAdmin" };
  }

  return true;
}
