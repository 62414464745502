import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isoWeek from "dayjs/plugin/isoWeek";

/**
 * Do NOT add isToday plugin as it does not work with the timezone plugin.
 * @see https://github.com/iamkun/dayjs/issues/1884
 */
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);

/**
 * Boot dayjs.
 *
 * Intended to be used by app.ts when the application is first loaded.
 */
function bootDayJs(): void {
  // All server dates are in UTC.
  dayjs.tz.setDefault("UTC");
}

export default bootDayJs;
