import type Permissions from "../types/Permissions.ts";

/**
 * Check the permission exists in the permissions array.
 *
 * Do not use this function to check user permissions.
 * This is a abstraction for other permission functions to use.
 * @param permissions   Array of permissions
 * @param permission  Permission to check
 * @returns boolean
 */
function canPerformPermission(permissions: Permissions, permission: string): boolean {
  if (permissions.includes("*")) {
    return true;
  }

  return permissions.includes(permission);
}

export default canPerformPermission;
