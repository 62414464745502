/* eslint-disable @typescript-eslint/promise-function-async */
import castToStatusCode from "@/app/http/functions/CastToStatusCode.ts";
import redirectIfAuthenticatedGuard from "@/auth/guards/RedirectIfAuthenticatedGuard.ts";
import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import type { RouteRecordRaw } from "vue-router";

declare module "vue-router" {
  interface RouteMeta {
    isGuest?: boolean;
  }
}

const GuestMeta = {
  isGuest: true,
};

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "login",

    component: /* istanbul ignore next */ () => import("@/auth/pages/loginPage/TheLoginPage.vue"),

    meta: {
      ...GuestMeta,
    },

    /* istanbul ignore next -- @preserve */
    async beforeEnter() {
      /* istanbul ignore next -- @preserve */
      return redirectIfAuthenticatedGuard({
        refreshAuthentication: true,
      });
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",

    component: /* istanbul ignore next -- @preserve */ () =>
      import("@/auth/pages/forgotPassword/TheForgotPasswordPage.vue"),

    meta: {
      ...GuestMeta,
    },

    beforeEnter: /* istanbul ignore next -- @preserve */ () => redirectIfAuthenticatedGuard(),
  },
  {
    path: "/reset-password/:token",
    name: "resetPassword",

    props: /* istanbul ignore next */ (route) => ({
      token: typeof route.params["token"] === "string" ? route.params["token"] : undefined,
      email: typeof route.query["email"] === "string" ? route.query["email"] : undefined,
    }),

    component: /* istanbul ignore next */ () => import("@/auth/pages/resetPassword/TheResetPasswordPage.vue"),

    meta: {
      ...GuestMeta,
    },

    beforeEnter: /* istanbul ignore next -- @preserve */ () => redirectIfAuthenticatedGuard(),
  },
  {
    path: "/invitation/accept",
    name: "acceptInvitation",

    props: /* istanbul ignore next */ (route) => ({
      invitationToken: typeof route.query["token"] === "string" ? route.query["token"] : undefined,
    }),

    component: /* istanbul ignore next */ () =>
      import("@/invitation/pages/invitationAccept/TheInvitationAcceptContainer.vue"),

    meta: {
      ...GuestMeta,
    },
  },
  {
    path: "/error",
    name: "error",
    component: /* istanbul ignore next */ () => import("@/base/pages/errorPage/TheBaseErrorPage.vue"),
    meta: {
      ...GuestMeta,
    },
    props: /* istanbul ignore next -- @preserve */ (route) => {
      return {
        statusCode: castToStatusCode(route.query["statusCode"]),
        errorMessage: typeof route.query["errorMessage"] === "string" ? route.query["errorMessage"] : undefined,
        isAuthenticated: useAuthenticationStore().isAuthenticated,
        isBorked: route.query["isBorked"] === "true",
        error: typeof route.query["error"] === "string" ? route.query["error"] : undefined,
      };
    },
  },
];

export default routes;
