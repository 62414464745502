import AdminRoutes from "./AdminRoutes.ts";
import AppRoutes from "./AppRoutes.ts";
import GuestRoutes from "./GuestRoutes.ts";

export default [
  ...AppRoutes,
  ...GuestRoutes,
  ...AdminRoutes,
  { path: "/:pathMatch(.*)*", redirect: { name: "error", query: { statusCode: 404 } } },
];
