import AudiencePartialSchema from "@/audience/schemas/AudiencePartialSchema.ts";
import { CompanyTagSchema } from "@/tag/schemas/TagSchema.ts";
import TaskConfigSchema from "@/task/schemas/TaskConfigSchema.ts";
import z from "zod";
import TaskPublishedStatusEnum from "../enums/TaskPublishedStatusEnum.ts";
import taskSettingsSchema from "./TaskSettingsSchema.ts";

const TaskSchema = z.object({
  id: z.number(),
  name: z.string().max(50),
  description: z.string().max(255).nullable(),
  is_draft: z.boolean(),
  published_status: z.nativeEnum(TaskPublishedStatusEnum),
  draft_task_version_id: z.number().nullable(),
  current_content_version_id: z.number().nullable(),
  config: TaskConfigSchema.nullable(),
  target_config_id: z.number().nullable(),
  is_template: z.boolean(),
  can_have_unscheduled_submissions: z.boolean(),
  can_be_archived: z.boolean(),
  can_create_new_task_version: z.boolean(),
  settings: taskSettingsSchema,
  frequency: z.string(),
  audiences: z.array(AudiencePartialSchema),
  is_scored: z.boolean(),
  total_points: z.number().nullable(),
  work_departments: z.array(CompanyTagSchema),
});

const TasksSchema = z.array(TaskSchema);

const TaskNameSchema = z.string().min(8).max(50).trim();

const TaskDescriptionSchema = z.string().min(1).max(254).trim();

export { TaskDescriptionSchema, TaskNameSchema, TaskSchema, TasksSchema };
