import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import { createRouteLocation } from "@/routing/functions/NavigateToErrorPage.ts";
import * as Sentry from "@sentry/vue";
import type { RouteLocationRaw } from "vue-router";

/**
 * Makes sure the authenticated user has a current company
 *
 * - This is to prevent homeless users from accessing company routes
 * - Display an error message so they can contact support if necessary
 * @returns returns boolean to continue or the error route location
 */
export default function currentCompanyGuard(): RouteLocationRaw | boolean {
  const store = useAuthenticationStore();

  try {
    store.findCurrentCompanyIdOrFail();
  } catch (error) {
    Sentry.captureMessage("User does not have an active company", "warning");

    return createRouteLocation(
      undefined,
      "You need an active company to gain access. Please contact support.",
      "Unknown Company Error",
      true
    );
  }

  return true;
}
