import z from "zod";

const DayOfTheWeekSchema = z.enum(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]);
const HoursOfTheDaySchema = z.nativeEnum({
  zero: 0 as const,
  one: 1 as const,
  two: 2 as const,
  three: 3 as const,
  four: 4 as const,
  five: 5 as const,
  six: 6 as const,
  seven: 7 as const,
  eight: 8 as const,
  nine: 9 as const,
  ten: 10 as const,
  eleven: 11 as const,
  twelve: 12 as const,
  thirteen: 13 as const,
  fourteen: 14 as const,
  fifteen: 15 as const,
  sixteen: 16 as const,
  seventeen: 17 as const,
  eighteen: 18 as const,
  nineteen: 19 as const,
  twenty: 20 as const,
  twenty_one: 21 as const,
  twenty_two: 22 as const,
  twenty_three: 23 as const,
});

const TaskVersionConfig = z
  .object({
    schedule: z.object({
      schedule_type: z.enum(["repeating", "unscheduled"]),
      frequency: z.enum(["daily", "hourly", "monthly", "weekly", "semi_annually", "annually"]).optional(),
      cron_expression: z.string().min(1).optional(),
      days: z
        .object({
          type: z.enum(["every_day", "select_days"]),
          days_of_the_week: z.array(DayOfTheWeekSchema),
        })
        .optional(),
      hours: z
        .object({
          type: z.enum(["every_hour", "select_hours"]),
          hours_of_the_day: z.array(HoursOfTheDaySchema),
        })
        .optional(),
      week: z
        .object({
          start_day: DayOfTheWeekSchema,
        })
        .optional(),
      annually: z
        .object({
          months: z.array(z.number().int().min(1).max(12)).min(1),
        })
        .optional(),
      semi_annually: z
        .object({
          first_month: z.number().int().min(1).max(12),
          second_month: z.number().int().min(1).max(12),
        })
        .optional(),
      times: z
        .object({
          start_time_type: z.enum(["set_time", "start_of_day"]),
          start_time: HoursOfTheDaySchema.optional(),
          end_time_type: z.enum(["end_of_day", "set_time"]),
          end_time: HoursOfTheDaySchema.optional(),
        })
        .refine(
          (times) =>
            typeof times.start_time === "number" && typeof times.end_time === "number"
              ? times.end_time > times.start_time
              : true,
          {
            message: "End time must be greater than start time",
            path: ["end_time"],
          }
        )
        .optional(),
    }),
  })
  .nullable()
  .refine(
    (schema) => {
      return !(schema?.schedule.schedule_type === "repeating" && schema.schedule.frequency === undefined);
    },
    { message: "Frequency is required for repeating schedule type", path: ["schedule", "frequency"] }
  );

export default TaskVersionConfig;
