import { CompanyTagSchema } from "@/tag/schemas/TagSchema.ts";
import z from "zod";
import AudienceEnum from "../Enums/AudienceEnum.ts";
import { SitePartialSchema } from "@/site/schemas/SiteSchema.ts";

const AudiencePartialSchema = z.object({
  id: z.number(),
  company_id: z.number().nullable(),
  is_template: z.boolean(),
  identifier: z.nativeEnum(AudienceEnum),
  audience_explanation: z.string(),
  audience_members: z.array(
    z.object({
      user_id: z.number(),
    })
  ),
  sites: z.array(SitePartialSchema),
  can_target_by_user: z.boolean(),
  can_target_by_work_department: z.boolean(),
  can_target_by_site: z.boolean(),
  targets_by_site: z.string().nullish(),
  work_departments: z.array(CompanyTagSchema),
});

export default AudiencePartialSchema;
