import useAuthenticationStore from "@/auth/stores/AuthenticationStore.ts";
import { createRouteLocation } from "@/routing/functions/NavigateToErrorPage.ts";
import type { NavigationGuard, RouteLocationRaw, RouteLocationNormalizedGeneric } from "vue-router";
import canPerformSitePermission from "../functions/CanPerformSitePermission.ts";
import canPerformCompanyPermission from "../functions/CanPerformCompanyPermission.ts";

/**
 * Call the site permission guard
 * @param permission the permission to check
 * @returns an error route location if not authorized or do not have permission. Otherwise, returns true
 */
function createSitePermissionGuard(permission: string): NavigationGuard {
  // Create guard to check if the user is authenticated and has the permission.
  return (to: RouteLocationNormalizedGeneric): RouteLocationRaw | boolean => {
    const authenticationStore = useAuthenticationStore();

    if (!authenticationStore.isAuthenticated) {
      return createRouteLocation(401, undefined);
    }

    if (canPerformCompanyPermission(permission)) {
      return true;
    }

    const siteIdString = to.params["siteId"];

    if (typeof siteIdString !== "string") return createRouteLocation(403, undefined);

    const siteId = Number.parseInt(siteIdString, 10);

    if (!canPerformSitePermission(siteId, permission)) {
      return createRouteLocation(403, undefined);
    }

    return true;
  };
}

export default createSitePermissionGuard;
