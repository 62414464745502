import AnalyticEventsEnum from "@/analytics/enums/AnalyticEventsEnum.ts";
import analyticTrack from "@/analytics/functions/AnalyticEvent.ts";
import type { AxiosError } from "axios";
import { HttpStatusCode, isAxiosError } from "axios";
import errorHandler, { reportableStatusCodes } from "../error/ErrorHandler.ts";

/**
 * Catches all global axios errors
 * @param error caught axios error
 * @returns resolved promise
 */
export default async function globalAxiosCatchInterceptor(error: AxiosError | Error | unknown): Promise<Error> {
  if (isAxiosError(error)) {
    /* istanbul ignore if -- @preserve */
    if (error.response === undefined) {
      errorHandler(error);

      return Promise.reject(error);
    }

    const status = error.response.status as HttpStatusCode;

    /**
     * Redirect the user to login screen if error is a response
     * and has unauthorized status code
     */
    if ([HttpStatusCode.Unauthorized, 419].includes(status) && !window.location.pathname.startsWith("/login")) {
      if (error.response.status === 419) {
        analyticTrack(AnalyticEventsEnum.pageExpired);
      }

      window.location.href = "/login";
    }

    /**
     * When a 502 happens we want to report it to sentry.
     * This is because the server error could not have been captured by the server.
     * I.e api gone over timeout limit of 10 seconds.
     */
    if (reportableStatusCodes.includes(status)) {
      errorHandler(error);
    }
  }
  return Promise.reject(error);
}
